<template lang='pug'>
  v-content.main-content
    //- Login

    v-dialog(overlay-color='secondary'  overlay-opacity='0.7' max-width="500" v-model="loginDialog" v-if="loginDialog")
      login(userType="Buyer" @close="closeDialog" @switchDialog="openDialog('register')")
    //- Register
    v-dialog(  overlay-color='secondary' overlay-opacity='0.7' max-width="500" v-model="registerDialog" v-if="registerDialog").ma-0
      register(userType="Buyer" @close="closeDialog" @switchDialog="openDialog('login')")
    router-view
    bottom-nav(v-if="isMobile")
    dashboard-core-footer(v-show="!isMobile || isMobile && $route.name === 'Profile'")
</template>

<script>
  export default {
    name: 'DashboardCoreView',
    components: {
      login: () => import('@/views/pages/Login'),
      register: () => import('@/views/pages/Register'),
      DashboardCoreFooter: () => import('./Footer'),
      BottomNav: () => import('./BottomNav'),
    },
    data () {
      return {
        loginDialog: false,
        registerDialog: false,
      }
    },
    computed: {
      isMobile () {
        return window.innerWidth < 1024
      },
      currentDialog () {
        return this.$store.state.dialogs.currentItem
      },
    },
    watch: {
      currentDialog (newVal, oldVal) {
        newVal === 'login' ? this.loginDialog = true : this.loginDialog = false
        newVal === 'register' ? this.registerDialog = true : this.registerDialog = false
      },
    },
    methods: {
      openDialog (dialog) {
        this.$store.dispatch('dialogs/openDialog', dialog).then(function () {
          // console.log('dialog set on : ' + dialog)
        })
      },
      closeDialog () {
        this.$store.dispatch('dialogs/closeDialog').then(function () {
          // dialogs closed
        })
      },
    },
  }
</script>
<style lang="scss">
</style>
